import React from 'react';
import './App.css';
import type {Node} from 'react';
import { readContract } from '@wagmi/core';
import { writeContract } from '@wagmi/core';
import { Icon } from '@iconify/react';
import { configureChains, createConfig, WagmiConfig, useAccount, useNetwork } from 'wagmi'; 
import { publicProvider } from 'wagmi/providers/public'; import { disconnect } from '@wagmi/core';
import { getDefaultWallets, RainbowKitProvider, useConnectModal, useChainModal } from '@rainbow-me/rainbowkit'; import '@rainbow-me/rainbowkit/styles.css';
import YachtingVerse from './YachtingVerse.json';
import Staking from './Staking.json';

function getValueFromExistingSmartContract(account, address, jsonFile, functionName, inputTypeList, outputTypeList, chainInfo, setChainInfo, updateChecks, ...argsIn){
	
	var defaultSlate = {};

	function coverValueIfNecc(type, value){
		if (type.t === 'ListType'){
			return value.map((aVal, index)=>{
				return coverValueIfNecc(type.c, aVal);
			})
		}else if (type.t === 'Object'){
			var p = {};
			type.c.forEach((aC, index)=>{
				var cc = coverValueIfNecc(aC, value[aC.n]);
				p[aC.n] = cc;
			})
			return p;
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			if (!value.hex){
	  			return BigInt(value);
			}
		}else if (type.t === 'Text String'){
			return value.split('.infura').join('');
		}
		return value;
	}

	function flattenType(inputType, aI){
		if (inputType.t === 'ListType'){
			return aI.map((anInput, index)=>{
				return flattenType(inputType.c, anInput);
			}).join(', ');
		}else if (inputType.t === 'UInteger' || inputType.t === 'Integer'){
			return aI.toString();
		}else if (inputType.t === 'Boolean'){
			if (aI){
				return 'true'
			}else{
				return 'false'
			}
		}else if (inputType.t === 'Object'){
			var cc = {};
			inputType.c.forEach((anInput, index)=>{
				var p = flattenType(anInput, aI[anInput.n]);
				cc[anInput.n] = p;
			})
			return JSON.stringify(cc);
		}else if (inputType.t === 'Bytes'){
			return '_';
		}else if (inputType.t === 'Text String' || inputType.t === 'Address'){
			return aI;
		}else{
			console.warn(inputType);
			return aI;
		}
	}

	if (account){

		var args = argsIn.filter((aI, index)=>{
			return index < inputTypeList.length;
		})

		var flattenedInputs = args.map((aI, index)=>{
			var inputType = inputTypeList[+index];
			return flattenType(inputType, aI);
		})

		var point = [address, functionName].concat(flattenedInputs);
		var pOut = layoutFoundationForAnObject(point, chainInfo);
		if (pOut[0] !== undefined){
			return pOut;
		}else{

			function actuallyCheck(){
				var gotNotChecked = false;
				for (var i = 0; i < updateChecks.length; i++){
					if (!updateChecks[i]){
						gotNotChecked = true;
						break;
					}
				}
				if (gotNotChecked){
					setTimeout(function(e){ actuallyCheck(); }, 500);
				}else{

			        var stuff = {
			          address,
			          abi: jsonFile,
			          args,
			          functionName,
			        };

			        readContract(stuff).then((value)=>{
						var k = {checked:true}
						if (outputTypeList.length === 1){
							k[0] = coverValueIfNecc(outputTypeList[0] , value);
						}else{
							for (var i = 0; i < outputTypeList.length; i++){
								var aVal = coverValueIfNecc(outputTypeList[i], value[i]);
								k[i] = aVal;
							}
						}
						replacement(point, chainInfo, k);
						setChainInfo({...chainInfo});
			        }).catch((e)=>{
						console.log(e);
			        });

				}
			}

			actuallyCheck();
			return defaultSlate;
		}
	}else{
		return defaultSlate;
	}
}

function defaultValue(type, path){
	for (var i = 0; i < path.length; i++){
		if (path[i].t === 'l'){
			type = type.c;
		}else if (path[i].t === 'oP'){
			for (var j = 0; j < type.c.length; j++){
				if (type.c[j].n === path[i].v){
					type = type.c[j].t;
					break;
				}
			}
		}
	}

	function processDefault(type){
		if (type.t === 'ListType'){
			return [];
		}else if (type.t === 'Object'){
			var out = {};
			for (var i = 0; i < type.c.length; i++){
				out[type.c[i].n] = processDefault(type.c[i].t);
			}
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			return BigInt('0');
		}else if (type.t === 'Text String'){
			return '-';
		}else if (type.t === 'Address'){
			return '0x0000000000000000000000000000000000000000'
		}else if (type.t === 'Boolean'){
			return false;
		}
	}
	return processDefault(type);
}

const DecimalInputRecall = ({defaultValue, style, className, onChange, idNos, inputValues, setInputValues, gVs}): Node => {

		var onChangeExt = onChange;
		var idOut = [idNos].concat(gVs).join('_');
		
		var value = (inputValues[idOut]? inputValues[idOut] : '');
		
		function setValue(valueIn){
				inputValues[idOut] = valueIn;
				setInputValues({...inputValues});
		}

		React.useEffect(() => {
				setValue(defaultValue + '');
		}, [defaultValue + '']);

		function onChange1(e){
				var valueOut = e.target.value;
				setValue(valueOut);
				if (onChangeExt){
						if (valueOut.indexOf('.') === valueOut.length - 1 || valueOut === '+' ){
						}else{
								onChangeExt(valueOut);
						}
				}
		}

		return <input className={className} value={value} onChange={onChange1} disabled={style.disabled} placeholder={style.placeholder} style={style} />;  
}

function textToDecimal(input){
		var p = isDecimalText(input);
		if (!p){
				return 0;
		}else{
				return +input;
		}
}

function makeADecimalTextIntoLongText(decimalText, digits){
		var locOfDot = decimalText.indexOf('.');
		if (locOfDot === -1){
				return decimalText + makeDigits(digits);
		}else{
				var before = decimalText.substr(0, locOfDot);
				var after = decimalText.substr(locOfDot + 1);
				if (after.length > digits){
						return before + after.substr(0, digits);      
				}else{
						return before + after + makeDigits(digits - after.length);
				}
		}
}

function makeDigits(digits){
		var x = '';
		for (var i = 0; i < digits; i++){
				x += '0';
		}
		return x;
}

function shortenName(text){
				if (text.length < 9){
								return text;
				}    
				return text.substr(0, 2) + '...' + text.substr(text.length - 4);
}

function layoutFoundationForAnObject(list, chainInfo){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		var p1 = p[list[i]];
		if (!p1){
			p[list[i]] = {};
			p1 = p[list[i]];
		}
		p = p1;
	}
	return p;
}

function replacement(list, chainInfo, object){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		if (i === list.length - 1){
			p[list[i]] = object;
		}else{
			p = p[list[i]];
		}
	}
}

function isDecimalText(thisVal){
				if (thisVal && (typeof thisVal === 'string' || thisVal instanceof String)){
						var regex3 = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/
						return thisVal.match(regex3);    
				}
				return false;
		}


const App = (): Node => {

	/* global BigInt */
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();
	const { address, isConnected } = useAccount();
	const { chain } = useNetwork();
	var chainId = BigInt(0);
	if (chain){
		chainId = BigInt(chain.id);
	}
	const [inputValues, setInputValues] = React.useState({})
	const [menu, setMenu] = React.useState(false);
	const [chainInfo, setChainInfo] = React.useState({});
	function clickActionfe_c0_43fab28d__s_1_k_c0_f975b388_i_c1_a28ca823_i_c2_f83b98b2_i_c3_c75c0277_i_c3_044a4a91(e){
		writeContract({ address:'0xdE70AeD3d14d39b4955147EfcF272334bdB75AB5', abi: YachtingVerse.abi, args:['0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', BigInt( makeADecimalTextIntoLongText(textToDecimal(inputValues['fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c2_f83b98b2 i c4_4fc50d58 i c4_62c85b33']) + '', Number(BigInt('18'))))], functionName: 'approve'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		e.stopPropagation();
	}
	function clickActionfe_c0_43fab28d__s_1_k_c0_f975b388_i_c1_a28ca823_i_c2_f83b98b2_i_c3_c75c0277_i_c287_97220414(e){
		writeContract({ address:'0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', abi: Staking.abi, args:[BigInt( makeADecimalTextIntoLongText(textToDecimal(inputValues['fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c2_f83b98b2 i c4_4fc50d58 i c4_62c85b33']) + '', Number(BigInt('18'))))], functionName: 'stake'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		e.stopPropagation();
	}
	function clickActionfe_c0_43fab28d__s_1_k_c0_f975b388_i_c1_a28ca823_i_c212_0b9fb05d_i_c3_c75c0277_i_c3_044a4a91(e){
		writeContract({ address:'0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', abi: Staking.abi, args:[BigInt( makeADecimalTextIntoLongText(textToDecimal(inputValues['fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c212_0b9fb05d i c4_4fc50d58 i c4_62c85b33']) + '', Number(BigInt('18'))))], functionName: 'unstake'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		e.stopPropagation();
	}
	function clickActionfe_c0_43fab28d__s_1_k_c0_f975b388_i_c12_7bf65b41_i_c212_0b9fb05d_i_c3_c75c0277_i_c3_044a4a91(e){
		writeContract({ address:'0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', abi: Staking.abi, args:[], functionName: 'withdrawAllInterestWithoutUnstaking'}).then((aResponse)=>{ console.log(aResponse);}).catch((e0)=>{
		window.alert(e0)}).finally(() => {setTimeout(function(){setChainInfo({});}, 15000);})
		e.stopPropagation();
	}
	function clickActionfe_c0_43fab28d_nv_ir_c0_0c93c4fe(e){
		if (isConnected){
			if (chainId === BigInt(42161)){
				disconnect();
			}else{
				openChainModal();
			}
		}else{
			openConnectModal();
		}
		getInfo();
		
		e.stopPropagation();
	}
	function clickActionfo_Footer_k_c52_43fa9f5d_i_c56_3e3d43ca_i_c69_11d538db(e){
		window.open('https://twitter.com/YachtingVerse');
		e.stopPropagation();
	}
	function clickActionfo_Footer_k_c52_43fa9f5d_i_c56_3e3d43ca_i_c74_11c3b8de(e){
		window.open('https://discord.com/invite/YnwAwxWgck');
		e.stopPropagation();
	}
	function getInfo(){
		setChainInfo({}); 
	}
	var footer = <div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, backgroundColor:'rgb(255, 255, 255)'}}>
				<div className=' '><div style={{backgroundColor:'#d7eaf3'}} className='     '>
					<div className=' '><div style={{backgroundColor:'rgb(255, 255, 255)'}} className='row   justify-content-center  align-items-start'>
						<button style={{cursor:'pointer'}} className='btn btn-link    col-1    '  onClick={clickActionfo_Footer_k_c52_43fa9f5d_i_c56_3e3d43ca_i_c69_11d538db} ><Icon height={'20px'} icon={'akar-icons:twitter-fill'} style={{color:'#14397d'}} /> {''}</button>
						<button style={{cursor:'pointer'}} className='btn btn-link    col-1    '  onClick={clickActionfo_Footer_k_c52_43fa9f5d_i_c56_3e3d43ca_i_c74_11c3b8de} ><Icon height={'20px'} icon={'akar-icons:discord-fill'} style={{color:'#14397d'}} /> {''}</button>
					</div></div>
					<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
						<div className='  col-12      text-center' style={{backgroundColor:'rgb(12, 25, 65)', borderWidth:0, padding:10}}>© 2023 YachtingVerse All Rights Reserved.
						</div>
					</div></div>
				</div></div>
			</div>
	return <div style={{color:'rgb(255, 255, 255)', fontFamily:'Poppins', backgroundColor:'#d7eaf3'}}>
		<nav className='navbar navbar-expand-md  navbar-light' style={{backgroundColor:'rgb(12, 25, 65)'}}>
			<span className='navbar-brand'><img src={'https://www.cues.sg/client_pictures/1090_hEjeoMPi.png'} style={{width:'237.61px'}} alt='logo' /><span ></span></span><button className='navbar-toggler' onClick={(e)=>{setMenu(!menu)}} style={{borderColor:'black', border: '0'}}><Icon height={'20px'} icon={'ion:menu-outline'} style={{color:'rgb(248, 237, 227)'}} /> </button><div className={'collapse navbar-collapse' + (menu ? ' show' : '')}><ul className='navbar-nav mr-auto w-100'></ul><ul className='navbar-nav ml-auto w-100 justify-content-end'>
			
<button style={{fontSize:'14px', cursor:'pointer'}} className='btn btn-light  '  onClick={clickActionfe_c0_43fab28d_nv_ir_c0_0c93c4fe} >{(isConnected ? ((chainId === BigInt(42161)) ? shortenName((address ? address : '')) : ('Connect to the ' + 'Arbitrum One' + ' chain')) : 'Connect Wallet')}</button></ul></div>
		</nav>
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-4  mb-4  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12      text-center' style={{backgroundColor:'rgb(12, 25, 65)', borderWidth:0, padding:10}}><span key={0} style={{fontSize:'calc(20px + 3em)'}}><span style={{fontFamily:'Poppins'}}><b>YachtingVerse Holder Reflection</b></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-center  align-items-start'>
					<div className='   col-10'><div style={{borderColor:'rgb(250, 37, 94)', borderWidth:1, borderStyle:'none', borderRadius:'0em', backgroundColor:'rgb(12, 25, 65)'}} className='  mt-2  mb-2    container-fluid'>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{backgroundColor:'rgb(12, 25, 65)', lineHeight:'2.09em', borderWidth:0, padding:10}}><span key={0} style={{fontSize:'30px'}}><span style={{color:'rgb(255, 255, 255)'}}><b>Deposit</b></span></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-6     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}><b>YACHT Amount</b></span>
							</div>
							<div className='  col-6     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}>Balance: </span>
								<span key={1} style={{fontSize:'16px'}}>{Number((BigInt('18') > BigInt('2') ? Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0xdE70AeD3d14d39b4955147EfcF272334bdB75AB5', YachtingVerse.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) / (BigInt('10') ** (BigInt('18') - BigInt('2')))) /(10 ** Number(BigInt('2'))) : Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0xdE70AeD3d14d39b4955147EfcF272334bdB75AB5', YachtingVerse.abi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) / (10 ** Number(BigInt('18'))))).toFixed(Number(BigInt('2')) > 100 ? 100 : Number(BigInt('2')))}</span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-1    '/>
							<div  className='container-fluid' style={{padding:'0'}}><DecimalInputRecall defaultValue={0} className='form-control   text-left ' style={{backgroundColor:'rgb(255, 255, 255)', placeholder:'', color:'rgb(12, 25, 65)', disabled:false, borderColor:'rgb(248, 237, 227)', borderWidth:1, borderStyle:'solid'}} gVs={[]} setInputValues={setInputValues} inputValues={inputValues} idNos={'fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c2_f83b98b2 i c4_4fc50d58 i c4_62c85b33'}/>{function(){ var p = []; var valueOut = inputValues['fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c2_f83b98b2 i c4_4fc50d58 i c4_62c85b33']; if (!isDecimalText(valueOut) && valueOut){ p.push('Not a Decimal');};  if (p.length > 0){ return <center><p style={{color:'red'}}>{p.join(', ')}</p></center>}else{ return null; }}()}</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>

						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
					</div></div>
					<div className='   col-10'><div style={{borderColor:'rgb(250, 37, 94)', borderWidth:1, borderStyle:'none', borderRadius:'0em', backgroundColor:'rgb(12, 25, 65)'}} className='  mt-2  mb-2    container-fluid'>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{lineHeight:'2.09em', borderWidth:0, padding:10}}><span key={0} style={{fontSize:'30px'}}><b>Withdraw</b></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-0  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-6     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}><b>YACHT Amount</b></span>
							</div>
							<div className='  col-6     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}>Balance: </span>
								<span key={1} style={{fontSize:'16px'}}>{Number((BigInt('18') > BigInt('2') ? Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', Staking.abi, 'informationAboutStakeScheme', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], []) / (BigInt('10') ** (BigInt('18') - BigInt('2')))) /(10 ** Number(BigInt('2'))) : Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', Staking.abi, 'informationAboutStakeScheme', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], [])) / (10 ** Number(BigInt('18'))))).toFixed(Number(BigInt('2')) > 100 ? 100 : Number(BigInt('2')))}</span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-1    '/>
							<div  className=' container-fluid' style={{padding:'0'}}><DecimalInputRecall defaultValue={0} className='form-control   text-left ' style={{backgroundColor:'rgb(255, 255, 255)', placeholder:'', color:'rgb(12, 25, 65)', disabled:false, borderColor:'rgb(248, 237, 227)', borderWidth:1, borderStyle:'solid'}} gVs={[]} setInputValues={setInputValues} inputValues={inputValues} idNos={'fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c212_0b9fb05d i c4_4fc50d58 i c4_62c85b33'}/>{function(){ var p = []; var valueOut = inputValues['fe c0_43fab28d _s 1 k c0_f975b388 i c1_a28ca823 i c212_0b9fb05d i c4_4fc50d58 i c4_62c85b33']; if (!isDecimalText(valueOut) && valueOut){ p.push('Not a Decimal');};  if (p.length > 0){ return <center><p style={{color:'red'}}>{p.join(', ')}</p></center>}else{ return null; }}()}</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<button style={{cursor:'pointer'}} className='btn btn-light    col-5 col-sm-3    '  onClick={clickActionfe_c0_43fab28d__s_1_k_c0_f975b388_i_c1_a28ca823_i_c212_0b9fb05d_i_c3_c75c0277_i_c3_044a4a91} >{'Withdraw'}</button>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
					</div></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-center  align-items-start'>
					<div className='   col-10'><div style={{borderColor:'rgb(250, 37, 94)', borderWidth:1, borderStyle:'none', borderRadius:'0em', backgroundColor:'rgb(12, 25, 65)'}} className='  mt-2  mb-2    container-fluid'>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{backgroundColor:'rgb(12, 25, 65)', lineHeight:'2.09em', borderWidth:0, padding:10}}><span key={0} style={{fontSize:'30px'}}><b>Claim Rewards</b></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-6     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}><b>YACHT  Amount</b></span>
							</div>
							<div className='  col-6     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}>Balance: </span>
								<span key={1} style={{fontSize:'16px'}}>{Number((BigInt('18') > BigInt('2') ? Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', Staking.abi, 'interestEarnedUpToNowBeforeTaxesAndNotYetWithdrawn', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) / (BigInt('10') ** (BigInt('18') - BigInt('2')))) /(10 ** Number(BigInt('2'))) : Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', Staking.abi, 'interestEarnedUpToNowBeforeTaxesAndNotYetWithdrawn', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) / (10 ** Number(BigInt('18'))))).toFixed(Number(BigInt('2')) > 100 ? 100 : Number(BigInt('2')))}</span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-1    '/>
							<div  className='  container-fluid' style={{padding:'0'}}><DecimalInputRecall defaultValue={0} className='form-control   text-left ' style={{backgroundColor:'rgb(255, 255, 255)', placeholder:'', color:'rgb(12, 25, 65)', disabled:false, borderColor:'rgb(248, 237, 227)', borderWidth:1, borderStyle:'solid'}} gVs={[]} setInputValues={setInputValues} inputValues={inputValues} idNos={'fe c0_43fab28d _s 1 k c0_f975b388 i c12_7bf65b41 i c212_0b9fb05d i c4_4fc50d58 i c4_62c85b33'}/>{function(){ var p = []; var valueOut = inputValues['fe c0_43fab28d _s 1 k c0_f975b388 i c12_7bf65b41 i c212_0b9fb05d i c4_4fc50d58 i c4_62c85b33']; if (!isDecimalText(valueOut) && valueOut){ p.push('Not a Decimal');};  if (p.length > 0){ return <center><p style={{color:'red'}}>{p.join(', ')}</p></center>}else{ return null; }}()}</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>

						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
					</div></div>
				</div></div>
			</div></div>
		</div>
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-4  mb-4  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12      text-center' style={{backgroundColor:'rgb(255, 255, 255)', borderWidth:0, padding:10}}><span key={0} style={{fontSize:'calc(20px + 1.5em)'}}><span style={{color:'#14397d'}}><b>Statistics</b></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(255, 255, 255)'}} className='row   justify-content-start  align-items-start'>
					<div className='  col-1  mt-0  mb-0  ml-5 '/>
					<div className='   col-6 col-sm-4 col-lg-3'><div style={{backgroundColor:''}} className='  mt-1  mb-1   '>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<div className='  col-3    '><Icon height={'40px'} icon={'system-uicons:coins'} style={{color:'#14397d'}} /> </div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'20px'}}><span style={{color:'#14397d'}}>Total Staked</span></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}>
								<span key={0} style={{fontSize:'30px', color:'#14397d', fontWeight:'bold'}}>{Number((BigInt('18') > BigInt('4') ? Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', Staking.abi, 'totalStakedAmount', [], outputTypeList, chainInfo, setChainInfo, [], []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []) / (BigInt('10') ** (BigInt('18') - BigInt('4')))) /(10 ** Number(BigInt('4'))) : Number(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0x3Dec1208dfC15f70149d1C1d5aBD9C1EC44b3A58', Staking.abi, 'totalStakedAmount', [], outputTypeList, chainInfo, setChainInfo, [], []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], [])) / (10 ** Number(BigInt('18'))))).toFixed(Number(BigInt('2')) > 100 ? 100 : Number(BigInt('2')))}</span>
							</div>
						</div></div>
					</div></div>
					<div className='   col-6 col-sm-4 col-lg-3'><div style={{backgroundColor:''}} className='  mt-1  mb-1   '>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<div className='  col-3    '><Icon height={'40px'} icon={'healthicons:money-bag-outline'} style={{color:'#14397d'}} /> </div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'20px'}}><span style={{color:'rgb(20, 57, 125)'}}>Total Supply</span></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'30px'}}><span style={{color:'#14397d'}}><b>7500000 YACHT</b></span></span>
							</div>
						</div></div>
					</div></div>
					<div className='   col-6 col-sm-4 col-lg-3'><div style={{backgroundColor:''}} className='  mt-1  mb-1   '>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<div className='  col-3    '><Icon height={'40px'} icon={'healthicons:money-bag'} style={{color:'#14397d'}} /> </div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'20px'}}><span style={{color:'rgb(20, 57, 125)'}}>Circulation Supply</span></span><br/>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'30px'}}><span style={{color:'#14397d'}}><b>1500000 YACHT</b></span></span><br/>
							</div>
						</div></div>
					</div></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
				</div></div>
				<div className=' '><div style={{backgroundColor:'rgb(12, 25, 65)'}} className='row   justify-content-start  align-items-start'>
				</div></div>
			</div></div>
		</div>{footer}</div>
}

const App1 = () : Node => {

	var xx = {id:42161, name:'Arbitrum One', network:'arbitrum', nativeCurrency:{decimals:18, name:'ETH', symbol: 'ETH'}, rpcUrls:{default:{http:['https://1rpc.io/arb']}, public: {http:['https://1rpc.io/arb']}}, blockExplorers:{etherscan:{name:'Arbiscan',url:'https://arbiscan.io'}, default:{name:'Arbiscan', url:'https://arbiscan.io'}}, contracts:{ multicall3:{address:'0xca11bde05977b3631167028862be2a173976ca11', blockCreated:7654707}}}

	const { chains, publicClient, webSocketPublicClient } = configureChains(
		[xx],
		[publicProvider()]
	);

	const { connectors } = getDefaultWallets({
		appName: 'HolderRef',
		projectId: '92bf460051abf690b1804a07d79ae706',
		chains
	});

	const wagmiConfig = createConfig({
		autoConnect: true,
		connectors,
		publicClient,
		webSocketPublicClient,
	});

	return <WagmiConfig config={wagmiConfig}><RainbowKitProvider chains={chains}><App /></RainbowKitProvider></WagmiConfig>

	}

	

export default App1;